import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "Home" */ '../views/LbHome.vue')
  },
  {
    path: '/articles/:rep',
    name: 'Articles',
    props:true,
    component: () => import(/* webpackChunkName: "articles" */ '../views/LbHome.vue')
  },  {
    //path: '/article/:rep/:num/:editable',
    path: '/article/:rep/:num',
    name: 'Article',
    component: () => import(/* webpackChunkName: "machin" */ '../components/Article.vue'),
    props:true
  },
  {
    path: '/outillage',
    name: 'Outillage',
    component: () => import(/* webpackChunkName: "evenements" */ '../views/LbOutillage.vue')
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/LbContact.vue')
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: () => import(/* webpackChunkName: "calendar" */ '../views/LbCalendar.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
