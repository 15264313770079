<template>
  <v-app>

    <core-app-bar></core-app-bar>
    <core-drawer></core-drawer>
    <core-view></core-view>
    
  </v-app>
</template>

<script>
export default {
  name: 'App',

    components: {
      CoreAppBar: () => import('@/components/core/AppBar'),
      CoreDrawer: () => import('@/components/core/Drawer'),
      CoreView: () => import('@/components/core/View'),
    },

    data: () => ({
    })
  };
</script>




